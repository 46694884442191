@import 'variables/colors';
@import 'mixins';

.navbar {
  background-color: $color-theme-blue;
}

.prism-menu {
  display: contents;
}

.navbar-menu {
  flex-grow: 1;
  display: flex;
  min-height: 46px;
  align-self: flex-end;
  overflow: visible;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: $color-theme-orange;
  position: relative;
  flex-direction: inherit;

  li {
    &.nav-item {
      min-height: 46px;
    }

    &:first-child {
      border-top-left-radius: 8px;
    }
  }

  .nav-item {
    color: white;
    outline: none;
    display: flex;
    cursor: pointer;
    text-decoration: none;
    background-color: $color-theme-orange;

    @include color-transitions(300ms);

    &:hover {
      color: white;
      background-color: $color-theme-orange-dark;

      &.search-menu {
        min-height: auto;
      }
    }

    &.active {
      color: white;
      border-bottom: 2px solid $white;
    }

    .nav-name {
      margin: auto;
    }

    .dropdown-menu {
      background-color: $color-theme-orange;
      border-color: white;

      .dropdown-item {
        color: white;
        border-radius: 0;

        &:hover {
          color: white;
          background-color: #ff7338;
        }

        &.active {
          background-color: $color-theme-orange-dark;
        }
      }
    }
  }

  .global-search {
    position: absolute;
    right: 4px;
  }
}

ul.top-nav-menu {
  list-style: none;
  margin-top: 15px;

  li {
    display: inline-block;
    margin-right: 20px;

    a {
      color: white;
      text-decoration: none;
    }
  }
}

.nav-menu-item.active {
  font-weight: 800;
}
