/* You can add global styles to this file, and also import other style files */
@import 'core/classes';

* {
  margin: 0;
}

.bold {
  font-weight: bold;
}
.center {
  text-align: center;
}
.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
