@import "mixins/avatars";
@import "mixins/buttons";
@import "mixins/borders";
@import "mixins/card-accent";
@import "mixins/list-group";
@import "mixins/sidebar-width";
@import "mixins/switches";

@mixin color-transitions($delay) {
  transition: color $delay cubic-bezier(0, 0, 0.2, 1) 0ms,
  background-color $delay cubic-bezier(0, 0, 0.2, 1) 0ms,
  outline-color $delay cubic-bezier(0, 0, 0.2, 1) 0ms;
}

@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
