.bg-primary,
.bg-success,
.bg-info,
.bg-warning,
.bg-danger,
.bg-dark {
    color: #fff;
}

.bg-status-inactive,
.bg-status-pending,
.bg-status-partial {
    color: $dark;
}

@each $color, $value in $brands-colors {
    @include bg-variant(".bg-#{$color}", $value, true);
}

@each $color, $value in $colors {
    @include bg-variant(".bg-#{$color}", $value, true);
}

@each $color, $value in $grays {
    @include bg-variant(".bg-gray-#{$color}", $value, true);
}

@each $color, $value in $status-colors {
    @include bg-variant(".bg-status-#{$color}", $value, true);
}

.bg-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ($spacer * 2.5);
    height: ($spacer * 2.5);
}
