.row.row-equal {
  padding-right: calc(#{$grid-gutter-width} / 4);
  padding-left: calc(#{$grid-gutter-width} / 4);
  margin-right: calc(#{$grid-gutter-width} / -2);
  margin-left: calc(#{$grid-gutter-width} / -2);

  [class*="col-"] {
    padding-right: calc(#{$grid-gutter-width} / 4);
    padding-left: calc(#{$grid-gutter-width} / 4);
  }
}

.main .container-fluid {
  padding: 0 30px;
}
