.table-outline {
  border: 1px solid $table-border-color;

  td {
    vertical-align: middle;
  }
}

.table-align-middle {

  td {
    vertical-align: middle;
  }
}

.table-clear {
  td {
    border: 0;
  }
}

.table-scroll {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;

  table {
    width: 100%;
    margin: auto;
    border-spacing: 0;
    border-collapse: separate;
  }

  tbody {
    tr {
      &:first-child {
        td {
          border-top: none;
        }
      }
    }
  }

  thead {
    th {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      background: #fff;
    }
  }

  thead,
  tfoot {
    th {
      &:first-child {
        z-index: 5;
      }
    }
  }

  tfoot,
  tfoot th,
  tfoot td {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 4;
    background: #fff;
  }
}
