@import 'variables/colors';

.breadcrumb {
  background-color: $color-theme-back-1;

  .breadcrumb-item {
    color: $color-theme-orange;

    &:hover {
      color: $color-theme-orange;
    }
  }
}


